import { styled } from '@compiled/react';
import type { PropsWithChildren } from 'react';
import React from 'react';

import { token } from '@atlaskit/tokens';

import {
	GRADIENT_BACKGROUND_SUBTLEST_END,
	GRADIENT_BACKGROUND_SUBTLEST_START,
} from '../GradientLooknFeel/GradientConstants';
import type { ChangeEditionSectionMessageProps } from '../SectionMessage/ChangeEditionSectionMessage';
import { ChangeEditionSectionMessage } from '../SectionMessage/ChangeEditionSectionMessage';

// https://hello.atlassian.net/wiki/spaces/CE2/pages/2804105628/Feature+gates+with+Taste+Tone+styling#Upsell-variants
export const SectionMessage = ({
	children,
	withGradient = true,
	// TODO: refactor topBanner and customPadding per https://product-fabric.atlassian.net/browse/USD-337
	// for now since we only support 2 different cases, we give a boolean, going forward, if more use cases surface, we can revisit and design a better API
	topBanner = false,
	customPadding,
	...props
}: SectionMessageProps) => (
	<Layout
		withGradient={withGradient}
		topBanner={topBanner}
		customPadding={customPadding}
		data-testid="section-message"
	>
		{!!children && <ChildrenLayout>{children}</ChildrenLayout>}
		<ChangeEditionSectionMessage {...props} withTasteAndToneStyling />
	</Layout>
);

type LayoutProps = {
	withGradient?: boolean;
	topBanner?: boolean;
	customPadding?: string;
};

export type SectionMessageProps = PropsWithChildren<ChangeEditionSectionMessageProps> & LayoutProps;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ChildrenLayout = styled.div({
	marginRight: `${token('space.300')}`,
	maxWidth: 120,
	minWidth: 120,
});

const gradientBackground = `linear-gradient(84.09deg,
  ${GRADIENT_BACKGROUND_SUBTLEST_START} 0%,
  ${GRADIENT_BACKGROUND_SUBTLEST_END} 100%)`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Layout = styled.div<LayoutProps>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: (props) => (props.withGradient ? gradientBackground : 'none'),
	display: 'flex',
	margin: '0 auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: (props) => {
		if (props.customPadding) {
			return props.customPadding;
		}

		if (props.topBanner) {
			return `${token('space.150')} ${token('space.300')}`;
		}

		return `${token('space.200')} ${token('space.300')}`;
	},
	width: '100%',
	boxSizing: 'border-box',
	borderRadius: token('border.radius.100'),
	position: 'relative',
});
