import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { token } from '@atlaskit/tokens';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';

import type { FormattedWebItem } from '@confluence/web-item-location';

import { THIRD_PARTY } from './ContentTools';
import { LazyContentToolsItemWithPlaceholder } from './LazyContentToolsItem';

// This takes a list of webItems and splits them into two groups: first
// party and third party. Third party is denoted by the webItem having
// the subSection property set to "third-party". This function also
// applies the correct styling to the third party items as is done in
// createMenuItems.
export const partitionWebItems = (webItems: ReadonlyArray<FormattedWebItem>) => {
	const firstParty: FormattedWebItem[] = [];
	const thirdParty: FormattedWebItem[] = [];

	for (const webItem of webItems) {
		if (webItem?.['subSection'] === THIRD_PARTY) {
			webItem['className'] = webItem.styleClass;
			thirdParty.push(webItem);
		} else {
			firstParty.push(webItem);
		}
	}

	return { firstParty, thirdParty };
};

// This function takes a list of third party webItems and turns them into
// a list of React elements. The behavior is copied from createMenuItems.
export const constructThirdPartyMenuItems = (items: FormattedWebItem[]): React.ReactElement[] => {
	const itemsCopy = [...items];

	// reorder Slack Notification to the beginning of the list
	const slackIntegrationMenuItemIndex = itemsCopy.findIndex(
		(item) => item?.id === 'confluence-chats-integration__page-config-dialog-trigger',
	);
	if (slackIntegrationMenuItemIndex !== -1) {
		const slackIntegrationMenuItem = itemsCopy.splice(slackIntegrationMenuItemIndex, 1)[0];
		itemsCopy.splice(0, 0, slackIntegrationMenuItem);
	}

	return itemsCopy
		.filter(Boolean)
		.map((item) => <LazyContentToolsItemWithPlaceholder key={item?.completeKey} {...item} />);
};

export const insertThirdPartySection = (thirdPartySection, itemSections) => {
	const indexOfSectionWithAnalytics = itemSections.findIndex((section) => {
		return section?.some?.(
			(item) => item?.key === 'confluence.content.action.menu.new:view-history',
		);
	});

	if (indexOfSectionWithAnalytics === -1) {
		return [...itemSections, thirdPartySection];
	} else {
		// Create a copy so we don't mutate itemSections.
		const result = [...itemSections];
		result.splice(indexOfSectionWithAnalytics, 0, [thirdPartySection]);
		return result;
	}
};

// This creates the dropdown menu specifically for the "Apps" section
// which contains third party apps.
type ThirdPartyAppsSectionComponentProps = {
	menuItems: any;
	nestedPopup?: 'advanced' | 'attachments' | 'export' | 'content-owner' | 'third-party';
	onSetPopup: (popup: any) => void;
};
export const ThirdPartyAppsSubmenu = ({
	menuItems,
	nestedPopup,
	onSetPopup,
}: ThirdPartyAppsSectionComponentProps) => {
	const section = THIRD_PARTY;

	return (
		<DropdownMenu
			key={`${section}-dropdown`}
			placement="auto-start"
			isOpen={nestedPopup === section}
			onOpenChange={({ isOpen }) => {
				if (isOpen) {
					onSetPopup(section);
				} else {
					onSetPopup(undefined);
				}
			}}
			trigger={({ triggerRef, ...triggerProps }) => (
				<DropdownItem
					{...triggerProps}
					ref={triggerRef}
					testId={`${section}-button`}
					elemAfter={<ChevronRightIcon label="" color={token('color.icon')} />}
				>
					<FormattedMessage
						defaultMessage="Apps"
						id="content-tools.third-party-apps.nested.section.heading"
						description="The text that displays in the menu dropdown for the item that groups third party apps"
					/>
				</DropdownItem>
			)}
		>
			<DropdownItemGroup>{menuItems}</DropdownItemGroup>
		</DropdownMenu>
	);
};
