import { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER as persistedLocalStorageKeys,
} from '@confluence/storage-manager';
import { fg } from '@confluence/feature-gating';
import { isExpectedApolloError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';

import { useCanConvertPageToFolder } from './useCanConvertPageToFolder';
import { useConvertPageToFolderStore } from './ConvertPageToFolderStore';
import type {
	ConvertPageToFolderBannerQuery as ConvertPageToFolderBannerQueryType,
	ConvertPageToFolderBannerQueryVariables,
} from './__types__/ConvertPageToFolderBannerQuery';
import { ConvertPageToFolderBannerQuery } from './ConvertPageToFolderBannerQuery.graphql';

const STORAGE_KEY_DISMISSED_FOLDER_CONVERSION_BANNER =
	persistedLocalStorageKeys.PERSISTED_PAGE_TO_FOLDER_CONVERSION_BANNER_DISMISSED;

type UseShowConvertPageToFolderProps = {
	contentId: string;
	spaceKey: string;
	skipQueryIfBannerDisabledOrDismissed?: boolean;
};

export const useShowConvertPageToFolder = ({
	contentId,
	spaceKey,
	// Set to false only for the folder conversion spotlight, which was changed to have the
	// same conditions as the banner for when it shows up, excluding the local storage key check
	skipQueryIfBannerDisabledOrDismissed = true,
}: UseShowConvertPageToFolderProps) => {
	const bannerDismissedKeyExists = Boolean(
		localStorage?.getItem(
			persistedLocalStorageKeys.PERSISTED_PAGE_TO_FOLDER_CONVERSION_BANNER_DISMISSED,
		),
	);

	const [{ bannerDismissed }, { dismissBanner }] =
		useConvertPageToFolderStore(bannerDismissedKeyExists);

	const skipQueries =
		skipQueryIfBannerDisabledOrDismissed &&
		(bannerDismissed || !fg('confluence_enable_page_to_folder_conversion_banner'));

	const {
		canConvertPageToFolder,
		error: canConvertError,
		hasLoaded: hasLoadedCanConvert,
		contentTitle,
	} = useCanConvertPageToFolder({
		contentId,
		spaceKey,
		skipQuery: skipQueries,
	});

	const {
		data,
		loading,
		error: bannerError,
	} = useQuery<ConvertPageToFolderBannerQueryType, ConvertPageToFolderBannerQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ConvertPageToFolderBannerQuery,
		{
			skip: skipQueries || !canConvertPageToFolder,
			variables: { contentId },
		},
	);

	const isParent = (data?.singleContent?.children?.count || 0) > 0;

	const isPageFolderConversionCandidate =
		canConvertPageToFolder && data && checkBannerConditionsForPage(data) && isParent;

	const showBanner = useMemo(
		() => !bannerDismissed && isPageFolderConversionCandidate,
		[bannerDismissed, isPageFolderConversionCandidate],
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const handleOnClickClose = () => {
		localStorage?.setItem(STORAGE_KEY_DISMISSED_FOLDER_CONVERSION_BANNER, true);
		dismissBanner();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'dismissed',
				actionSubject: 'banner',
				actionSubjectId: 'convertPageToFolderBanner',
				source: 'viewPageScreen',
			},
		}).fire();
	};

	const error = canConvertError || bannerError;
	if (isExpectedApolloError(error)) {
		markErrorAsHandled(error);
	}

	const hasLoaded = evalHasLoaded({
		hasData: Boolean(data),
		hasError: Boolean(error),
		loading,
		hasLoadedCanConvert,
		canConvertPageToFolder,
		bannerDismissed,
		isFolderConversionBannerEnabled: fg('confluence_enable_page_to_folder_conversion_banner'),
	});

	return {
		showBanner,
		isPageFolderConversionCandidate,
		handleOnClickClose,
		error,
		canConvertPageToFolder,
		contentTitle,
		hasLoaded,
	};
};

export const evalHasLoaded = ({
	hasData,
	hasError,
	loading,
	bannerDismissed,
	hasLoadedCanConvert,
	isFolderConversionBannerEnabled,
	canConvertPageToFolder,
}: {
	hasData: boolean;
	hasError: boolean;
	loading: boolean;
	hasLoadedCanConvert: boolean;
	canConvertPageToFolder: boolean;
	bannerDismissed: boolean;
	isFolderConversionBannerEnabled: boolean;
}) => {
	const hasCheckBannerConditionsLoaded = (hasData || hasError) && !loading;
	return (
		bannerDismissed ||
		!isFolderConversionBannerEnabled ||
		(hasLoadedCanConvert && (!canConvertPageToFolder || hasCheckBannerConditionsLoaded))
	);
};

const checkBannerConditionsForPage = (data: ConvertPageToFolderBannerQueryType) => {
	try {
		const dynamicValue = data?.singleContent?.body?.dynamic?.value;
		const documentADF = dynamicValue && JSON.parse(dynamicValue);

		// Returns true if page body is empty or only contains new lines, whitespace or Children / Page Tree macros
		const isEmptyOrHasTreeMacro = documentADF?.content?.every((node: any) => {
			// Return true for Children macro
			if (node?.type === 'extension' && node?.attrs?.extensionKey === 'children') {
				return true;
			}
			// Return true for Page Tree macro or whitespace and new lines
			if (node?.type === 'paragraph') {
				// Return true for new line
				if (!node?.content) {
					return true;
				}

				// Return true for Page tree macro or whitespace
				return node?.content?.every((node: any) => {
					if (node?.type === 'text' && node?.text.trim() === '') {
						return true;
					}
					if (node?.type === 'inlineExtension' && node?.attrs?.extensionKey === 'pagetree') {
						return true;
					}
					return false;
				});
			}
			return false;
		});

		return isEmptyOrHasTreeMacro;
	} catch {
		return false;
	}
};
