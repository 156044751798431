import type { FC } from 'react';
import React, { useCallback, useContext } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { DropdownItem } from '@atlaskit/dropdown-menu';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import Tooltip from '@atlaskit/tooltip';
import { useFlags } from '@atlaskit/flag';
import SuccessIcon from '@atlaskit/icon/core/success';

import { ErrorDisplay, ErrorBoundary, Attribution } from '@confluence/error-boundary';
import {
	EndOfPageRecHideStatusMutation,
	EndOfPageRecommendationQuery,
} from '@confluence/page-recommendations';

import { EndOfPageRecHideQuery } from './EndOfPageRecHideQuery.graphql';
import type {
	EndOfPageRecHideQuery as EndOfPageRecHideQueryData,
	EndOfPageRecHideQueryVariables,
} from './__types__/EndOfPageRecHideQuery';
import { ContentToolsDropdownOverrideContext } from './ContentToolsComponent';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapper = styled.span({
	fontWeight: token('font.weight.regular'),
});

const i18n = defineMessages({
	hide: {
		id: 'content-tools.eop.hide',
		defaultMessage: 'Hide related content',
		description: 'Hide button in advanced menu section for end of page recommendations',
	},
	show: {
		id: 'content-tools.eop.show',
		defaultMessage: 'Show related content',
		description: 'Show button in advanced menu section for end of page recommendations',
	},
	flag: {
		id: 'content-tools.eop.flag.description',
		defaultMessage:
			'Automatically generated suggestions will appear below this content when available.',
		description:
			'Description of the end of page recommendation flag that appears when the show button is clicked',
	},
	flag_results: {
		id: 'content-tools.eop.flag.results',
		defaultMessage: 'Automatically generated suggestions are now available below this content.',
		description:
			'Description of the end of page recommendation flag that appears when the show button is clicked and recommendations are available',
	},
	flag_results_title: {
		id: 'content-tools.eop.flag.title',
		defaultMessage: 'Related content shown',
		description:
			'Title of the end of page recommendation flag that appears when the show button is clicked and recommendations are available',
	},
	flag_no_results: {
		id: 'content-tools.eop.flag.no-results',
		defaultMessage:
			'Automatically generated suggestions will appear below this content once we have some to show you.',
		description:
			'Description of the end of page recommendation flag that appears when the show button is clicked and recommendations are not available',
	},
	flag_refresh: {
		id: 'content-tools.eop.flag.refresh',
		defaultMessage: 'Refresh to check for related content',
		description: 'Description of the refresh page on the flag for end of page recommendations',
	},
	show_tooltip: {
		id: 'content-tools.eop.flag.tooltip',
		defaultMessage: 'Related content is off for this space',
		description: "Tooltip for 'Show' button when end of page recommendations are off for the space",
	},
});

type EndOfPageRecMenuItemProps = {
	entityId: string;
	entityType?: string;
	spaceKey: string;
	contentToolsRef: any;
};

export const EndOfPageRecMenuItem: FC<EndOfPageRecMenuItemProps> = ({
	entityId,
	entityType,
	spaceKey,
	contentToolsRef,
}) => {
	const toggleMenuChangeAllowed = useContext(ContentToolsDropdownOverrideContext) as (
		allowed,
	) => void;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const skip = entityType !== 'page';

	const { showFlag } = useFlags();

	const { error, data } = useQuery<EndOfPageRecHideQueryData, EndOfPageRecHideQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		EndOfPageRecHideQuery,
		{
			variables: {
				type: 'page',
				id: entityId,
				exp: 'end_of_page',
				key: spaceKey,
			},
			errorPolicy: 'all',
			skip,
		},
	);

	const [mutateHideStatus, { error: mutationError }] = useMutation(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		EndOfPageRecHideStatusMutation,
		{
			refetchQueries: [
				{
					query: EndOfPageRecommendationQuery,
					variables: {
						type: 'page',
						id: entityId,
						exp: 'end_of_page',
						key: spaceKey,
					},
				},
			],
		},
	);

	const userCanToggle = data?.getRecommendedPages?.status?.userCanToggle;

	const endOfPageRecSpaceEnabled = data?.getRecommendedPagesSpaceStatus?.recommendedPagesEnabled;

	const isEnabled = data?.getRecommendedPages?.status?.isEnabled;
	const hasRecommendedPages = data?.getRecommendedPages?.recommendedPages.length;

	const toggleEOPItemHover = useCallback(
		(hovered: boolean) => () => {
			toggleMenuChangeAllowed(!hovered);
		},
		[toggleMenuChangeAllowed],
	);

	const clickMenu = useCallback(() => {
		contentToolsRef.closeDialog();
		if (isEnabled) {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source: 'page',
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'hideEOPAdv',
					attributes: {
						contentId: entityId,
						triggeredFrom: 'actionsMenu',
					},
				},
			}).fire();
			contentToolsRef.setIsEndOfPageHideConfirmOpen(true);
		} else {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source: 'page',
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'showEOPAdv',
					attributes: {
						contentId: entityId,
						triggeredFrom: 'actionsMenu',
					},
				},
			}).fire();

			void mutateHideStatus({
				variables: {
					type: entityType,
					id: entityId,
					status: true,
				},
			});

			const flagTitle = hasRecommendedPages ? (
				<FormattedMessage {...i18n.flag_results_title} />
			) : (
				<TitleWrapper>
					<FormattedMessage {...i18n.flag_no_results} />
				</TitleWrapper>
			);
			const flagMessage = hasRecommendedPages ? <FormattedMessage {...i18n.flag_results} /> : null;
			showFlag({
				title: flagTitle,
				description: flagMessage,
				icon: <SuccessIcon color={token('color.icon.success')} label="" />,
				isAutoDismiss: true,
			});
		}
	}, [
		createAnalyticsEvent,
		mutateHideStatus,
		entityId,
		entityType,
		contentToolsRef,
		isEnabled,
		showFlag,
		hasRecommendedPages,
	]);

	if (skip || !userCanToggle) {
		return null;
	}

	if (error) {
		return (
			<ErrorBoundary attribution={Attribution.SMARTS}>
				<ErrorDisplay error={error} />
			</ErrorBoundary>
		);
	}

	if (mutationError) {
		return (
			<ErrorBoundary attribution={Attribution.SMARTS}>
				<ErrorDisplay error={mutationError} />
			</ErrorBoundary>
		);
	}

	const endOfPageRecToggleAdvMenuItem = (
		<DropdownItem
			isDisabled={!endOfPageRecSpaceEnabled}
			onClick={clickMenu}
			testId="eop-adv-menu-item"
		>
			{isEnabled ? <FormattedMessage {...i18n.hide} /> : <FormattedMessage {...i18n.show} />}
		</DropdownItem>
	);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions
		<div onMouseEnter={toggleEOPItemHover(true)} onMouseLeave={toggleEOPItemHover(false)}>
			{endOfPageRecSpaceEnabled ? (
				endOfPageRecToggleAdvMenuItem
			) : (
				<Tooltip content={<FormattedMessage {...i18n.show_tooltip} />} position="mouse">
					{endOfPageRecToggleAdvMenuItem}
				</Tooltip>
			)}
		</div>
	);
};
