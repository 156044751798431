import type { ComponentProps } from 'react';
import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	ArchivePagesLoader,
	ArchiveUpsellDialog,
	canUnarchivePage,
	ShowArchiveCTAQuery,
	UnarchivePagesDialogLoader,
} from '@confluence/archive-pages';
import { ConfluenceEdition } from '@confluence/change-edition/entry-points/ConfluenceEdition';
import { DialogsStateContainer } from '@confluence/dialogs';
import { Attribution, ErrorDisplay, withErrorBoundary } from '@confluence/error-boundary';
import {
	ARCHIVE_PAGES_EXPERIENCE,
	ExperienceTrackerContext,
	RESTORE_PAGES_EXPERIENCE,
} from '@confluence/experience-tracker';
import { useSessionData } from '@confluence/session-data';
import type { FormattedWebItem } from '@confluence/web-item-location';

import { LazyContentToolsItem } from './LazyContentToolsItem';

export const ACTION_ARCHIVE_PAGE_LINK = 'action-archive-link';
export const ACTION_RESTORE_PAGE_LINK = 'action-restore-link';

export const ARCHIVE_BUTTON_ANALYTICS_SOURCE = 'contentToolsItem';

export const ArchivePageItem = withErrorBoundary({
	attribution: Attribution.ADMIN_EXPERIENCE,
})(ArchivePageItemComponent);

function ArchivePageItemComponent({
	contentId,
	sectionItem,
	...props
}: ArchivePageItemComponentProps) {
	const { edition } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const experienceTracker = useContext(ExperienceTrackerContext);

	const { data, error, loading } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ShowArchiveCTAQuery,
		{
			variables: { contentId },
		},
	);

	if (loading) {
		return null;
	}
	if (error) {
		return <ErrorDisplay error={error} />;
	}

	const isFreeEdition = edition === ConfluenceEdition.FREE;
	const isArchiveAction = sectionItem.id === ACTION_ARCHIVE_PAGE_LINK;

	// If user is on Free edition and the current sectionItem.id is Archive,
	// display the Archive button.
	// If user is on Premium or Standard, check if the user has permissions to
	// archive/restore the page before displaying the Archive or Restore button.
	if (
		(isFreeEdition && isArchiveAction) ||
		canUnarchivePage(data?.content?.nodes?.[0]?.operations)
	) {
		return (
			<Subscribe to={[DialogsStateContainer]}>
				{(dialogs: DialogsStateContainer) => {
					const onClick = () => {
						createAnalyticsEvent({
							type: 'sendUIEvent',
							data: {
								action: 'clicked',
								actionSubject: isArchiveAction ? 'archiveButton' : 'restoreButton',
								actionSubjectId: isFreeEdition ? 'archivePageUpsell' : null,
								source: ARCHIVE_BUTTON_ANALYTICS_SOURCE,
								attributes: {
									confluenceEdition: edition,
								},
							},
						}).fire();

						if (isFreeEdition) {
							dialogs.showDialog(ArchiveUpsellDialog);
						} else {
							// Don't need to track experience for free edition as there is no archiving experience in free
							experienceTracker.start({
								name: isArchiveAction ? ARCHIVE_PAGES_EXPERIENCE : RESTORE_PAGES_EXPERIENCE,
							});
							dialogs.showDialog(
								// @ts-expect-error ArchivePagesLoader & UnarchivePagesDialogLoader predate the TypeScript support here
								isArchiveAction ? ArchivePagesLoader : UnarchivePagesDialogLoader,
								{ contentId, source: ARCHIVE_BUTTON_ANALYTICS_SOURCE },
							);
						}
					};

					return (
						<LazyContentToolsItem
							{...sectionItem}
							{...props}
							label={sectionItem.label}
							url={undefined}
							onClick={onClick}
						/>
					);
				}}
			</Subscribe>
		);
	}

	return null;
}

type ArchivePageItemComponentProps = {
	contentId: string;
	sectionItem: FormattedWebItem;
} & Partial<ComponentProps<typeof LazyContentToolsItem>>;
