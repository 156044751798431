import gql from 'graphql-tag';

export const EndOfPageRecommendationQuery = gql`
	query EndOfPageRecommendationQuery($type: String!, $id: ID!, $exp: String!, $key: ID!) {
		space(pageId: $id) {
			id
		}
		singleContent(id: $id) {
			id
			metadata {
				createdDate
				lastModifiedDate
			}
		}
		getRecommendedPages(entityType: $type, entityId: $id, experience: $exp) {
			recommendedPages {
				content {
					id
					title
					type
					subType
					links {
						webui
					}
					space {
						id
						key
						name
					}
					emoji: properties(key: "emoji-title-published") {
						nodes {
							id
							key
							value
						}
					}
				}
				strategy
			}
			status {
				userCanToggle
				isEnabled
			}
		}
		getRecommendedPagesSpaceStatus(entityId: $key) {
			recommendedPagesEnabled
		}
	}
`;
