import { useQuery } from '@apollo/react-hooks';

import { isExpectedApolloError } from '@confluence/error-boundary';
import { fg } from '@confluence/feature-gating';
import { markErrorAsHandled } from '@confluence/graphql';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { isViewPage } from '@confluence/named-routes';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';

import { ConvertPageToFolderQuery } from './ConvertPageToFolderQuery.graphql';
import type {
	ConvertPageToFolderQuery as ConvertPageToFolderQueryType,
	ConvertPageToFolderQueryVariables,
} from './__types__/ConvertPageToFolderQuery';

export const CONVERT_PAGE_TO_FOLDER_WEBITEM_ID = 'action-convert-page-to-folder';

export const useCanConvertPageToFolder = ({
	contentId,
	spaceKey,
	skipQuery,
}: {
	contentId: string;
	spaceKey: string;
	skipQuery?: boolean;
}) => {
	const { data, loading, error } = useQuery<
		ConvertPageToFolderQueryType,
		ConvertPageToFolderQueryVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ConvertPageToFolderQuery,
		{
			skip:
				Boolean(skipQuery) ||
				!fg('confluence_enable_page_to_folder_conversion') ||
				// Company Hub hides its space from the user, is rather presented to the user as "a new surface", not a page so converting it to a folder wouldn't make sense.
				// Additionally, if this query were to execute, it would break the Company Hub history/version preview, because it doesn't respect the versionOverride query parameter.
				isCompanyHubSpaceKey(spaceKey),
			variables: { contentId, spaceKey },
		},
	);

	const isViewPageRoute = isViewPage();
	const isLivePage = useIsCurrentPageLive();
	const isPage = data?.singleContent?.type === 'page';
	const isFoldersEnabledForSpace =
		data?.space?.spaceTypeSettings?.enabledContentTypes?.isFoldersEnabled;
	const isPublished = data?.singleContent?.status === 'current';

	let canEdit = false;
	let canArchive = false; // need to check archive permission since page is archived as a part of conversion
	data?.singleContent?.operations?.forEach((operationCheckResult) => {
		if (operationCheckResult?.targetType === 'page') {
			if (operationCheckResult?.operation === 'update') {
				canEdit = true;
			} else if (operationCheckResult?.operation === 'archive') {
				canArchive = true;
			}
		}
	});

	const canConvertPageToFolder = Boolean(
		isViewPageRoute &&
			!isLivePage &&
			isPage &&
			isFoldersEnabledForSpace &&
			isPublished &&
			canEdit &&
			canArchive,
	);

	if (isExpectedApolloError(error)) {
		markErrorAsHandled(error);
	}

	const contentTitle = data?.singleContent?.title;
	const hasLoaded = evalHasLoaded(
		Boolean(skipQuery) || !fg('confluence_enable_page_to_folder_conversion'),
		Boolean(data),
		Boolean(error),
		loading,
	);

	return {
		canConvertPageToFolder,
		contentTitle,
		loading,
		error,
		hasLoaded,
	};
};

export const evalHasLoaded = (
	skip: boolean,
	hasData: boolean,
	hasError: boolean,
	loading: boolean,
) => {
	return skip || ((hasData || hasError) && !loading);
};
